import { Component } from '@angular/core';

@Component({
  selector: 'fvrsu-configuration',
  templateUrl: './configuration.component.html',
  styles: [
  ]
})
export class ConfigurationComponent {

}
