import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ConfigurationComponent } from './configuration.component';

// Internal modules
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { SharedModule } from '../shared/shared.module';
import { StaticModule } from '../static/static.module';



@NgModule({
  declarations: [
    ConfigurationComponent,
  ],
  imports: [
    CommonModule,

    //Internal Modules
    ConfigurationRoutingModule,
    SharedModule,
    StaticModule
  ]
})
export class ConfigurationModule { }
