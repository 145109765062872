import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { ConfigurationComponent } from './configuration.component';
import { canAccessGuard } from '../auth/guards/access.guard';

const routes: Routes = [
  {
    path: '',
    component: ConfigurationComponent,
    children: [
      {
        path: 'tipos-operaciones',
        loadChildren: () => import('./operation-types/operation-types.module').then((m) => m.OperationTypesModule),
      },
      {
        path: 'tipos-alertas',
        loadChildren: () => import('./alert-types/alert-types.module').then((m) => m.AlertTypesModule),
      },
      {
        path: 'tipos-parametros',
        loadChildren: () => import('./parameter-types/parameter-types.module').then((m) => m.ParameterTypesModule),
      },
      {
        path: 'tipos-sensores',
        loadChildren: () => import('./sensor-types/sensor-types.module').then((m) => m.SensorTypesModule),
      },
      {
        path: 'estados',
        loadChildren: () => import('./status/status.module').then((m) => m.StatusModule),
      },
      {
        path: 'tipos-hardware',
        loadChildren: () => import('./hardwares/hardwares.module').then((m) => m.HardwaresModule),
      },
      {
        path: 'tipos-firmware',
        loadChildren: () => import('./firmwares/firmwares.module').then((m) => m.FirmwaresModule),
        data: { headerData: { title: 'Tipos de firmware' } },
      },
      {
        path: 'tipos-equipos',
        loadChildren: () => import('./equipment-types/equipment-types.module').then((m) => m.EquipmentTypesModule),
        data: {
          headerData: { title: 'Tipos de equipos', background: 'header-background-2.jpg' },
          roles: ['ROLE_ROOT']
        },
        canActivate: [canAccessGuard],
      },
      {
        path: 'modelos-equipos',
        loadChildren: () => import('./equipment-models/equipment-models.module').then((m) => m.EquipmentModelsModule),
        data: { headerData: { title: 'Modelos de equipos' } },
      },
      {
        path: 'categorias-equipos',
        loadChildren: () => import('./equipment-categories/equipment-categories.module').then((m) => m.EquipmentCategoriesModule),
        data: { headerData: { title: 'Categorías de equipos' } },
      },
      {
        path: 'grupos-equipos',
        loadChildren: () => import('./equipment-groups/equipment-groups.module').then((m) => m.EquipmentGroupsModule),
        data: { headerData: { title: 'Grupos de equipos' } },
      },
      {
        path: 'regiones',
        loadChildren: () => import('./regions/regions.module').then((m) => m.RegionsModule),
        data: { headerData: { title: 'Regiones' } },
      },
      {
        path: 'paises',
        loadChildren: () => import('./countries/countries.module').then((m) => m.CountriesModule),
        data: { headerData: { title: 'Países' } },
      },
      {
        path: 'ciudades',
        loadChildren: () => import('./cities/cities.module').then((m) => m.CitiesModule),
        data: { headerData: { title: 'Ciudades' } },
      },
    ],
  },
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  // { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurationRoutingModule {}
